/* MAT-THEME */
@import '~@angular/material/theming';
@include mat-core();

$md-primary: (
    50 : #f3f8e8,
    100 : #e2edc6,
    200 : #cfe1a0,
    300 : #bcd57a,
    400 : #adcc5e,
    500 : #9fc341,
    600 : #97bd3b,
    700 : #8db532,
    800 : #83ae2a,
    900 : #72a11c,
    A100 : #f1ffd9,
    A200 : #ddffa6,
    A400 : #caff73,
    A700 : #c1ff59,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #eaebeb,
    100 : #cbcece,
    200 : #a8adad,
    300 : #858c8c,
    400 : #6a7374,
    500 : #505a5b,
    600 : #495253,
    700 : #404849,
    800 : #373f40,
    900 : #272e2f,
    A100 : #82e9fa,
    A200 : #51e0f8,
    A400 : #17deff,
    A700 : #00d8fc,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$primary: mat-palette($md-primary);
$accent: mat-palette($md-secondary);
$warn: mat-palette($mat-red);

$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);


/* @Override Theme properties */
.material-icons {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
/* @Override Theme properties */
.mat-paginator,
.mat-tab-label,
.mat-checkbox,
.mat-radio-button,
.mat-form-field,
.mat-stepper-vertical, .mat-stepper-horizontal,
.mat-select,
.mat-option,
.mat-menu-item,
.mat-expansion-panel-header,
.mat-table,
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
    font-family: 'Quicksand', sans-serif !important;
}

mat-icon {
    // font-size: 22px !important;
    font-size: 20px !important;
    display: flex !important;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mat-step-icon .mat-icon {
    font-size: 16px !important;
}

.mat-menu-item,
.mat-option,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-size: 12px !important;    
    min-height: 40px;
    min-width: 40px !important;
}

.mat-dialog-container {
    padding: 0px !important;
    border-radius: 0px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 10px 0 0 0 !important;
    font-size: 11px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgb(0 0 0 / 73%) !important;
}

.mat-form-field-label {
    font-weight: 500 !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, 
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34375em) scale(0.75) !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: 0px;
}

.mat-date-range-input-inner[disabled] {
    color: rgb(0, 0, 0);
}

.mat-expansion-panel {
    // box-shadow: 0px 3px 3px -4px #00000069 !important;
    border-radius: 0px !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]), 
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]), 
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: rgba(0, 0, 0, 0.01) !important;
}

.mat-expansion-panel-header-description, 
.mat-expansion-indicator::after {
    color: rgba(0, 0, 0, 0.54);
    margin-top: -8px;
}

.mat-expansion-panel-header {
    height: 48px !important;
    padding: 0px 24px 0px 15px !important;
}

.mat-expansion-panel-header.mat-expanded {
    // background-color: rgb(245, 245, 245) !important;
    background-color: #fff !important;
    // margin-bottom: 12px;
    // border-bottom: 1px solid #e0e0e0;
}

.mat-accordion .mat-expansion-panel:last-of-type,
.mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: 0px !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    box-shadow: 0px 2px 3px -4px #000;
    border-radius: 0px !important;
}

// .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
//     background-color: #fafafa !important;
// }

.mat-accordion {
 
    .mat-expansion-panel {

        .mat-expansion-panel-header {

            .mat-expansion-panel-header-title {

                span {
                    font-size: 12px !important;
                    // letter-spacing: .5px;
                }
            }

            .mat-expansion-panel-header-description, 
            .mat-expansion-indicator::after {
                color: rgb(255, 255, 255);
                opacity: .8;
            }  
        }
        
        button {

            span {
                font-size: 11.5px !important;
                // color: #d2d2d2;
                letter-spacing: .5px;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0px;

        button {

            span {
                font-size: 11.5px !important;
            }
        }
    }

    mat-expansion-panel.mat-expanded {

        mat-expansion-panel-header {

            .mat-expansion-panel-header-description, 
            .mat-expansion-indicator::after {
                opacity: 1 !important;
            }                        
        }
    }       
}

.mat-tooltip.customTooltip,
.mat-tooltip{
  background-color: #5b5b5e;
}

tr.mat-header-row {
    // height: 48px !important;
    height: 60px !important;
}

tr.mat-row, tr.mat-footer-row {
    height: 54px !important;
}      

.mat-paginator {
    overflow: hidden;

    .mat-paginator-outer-container {
        
        .mat-paginator-container {
            min-height: 58px !important;
            font-size: 13px;
            justify-content: space-between;
        
            .mat-paginator-page-size {
        
                .mat-paginator-page-size-select {
                    margin: 0px 4px 0 4px !important;
                    height: 50px;
                }    
            }

            .mat-paginator-range-actions {
                
                .mat-paginator-range-label {
                    margin: 0 32px -5px 24px;
                    font-size: 13px;
                }
            }
        }
    }
}

.mat-vertical-stepper-header {
    padding: 10px 24px !important;
}

// .mat-step-header {
//     pointer-events: none !important;
// }

.mat-stepper-vertical {

    .mat-step-header {

        .mat-step-label, 
        .mat-step-optional,
        .mat-step-label.mat-step-label-active {
            font-size: 12px !important;
            font-weight: 600 !important;
        } 

        .mat-step-label.mat-step-label-active {
            color: rgb(0 0 0) !important;
        }      
    }
}

.mat-radio-label-content {
    width: 100%;
}

.mat-radio-label-content {
    padding-left: 15px !important;
}

.mat-horizontal-content-container {
    padding-bottom: 20px !important;
}

.mat-step-header .mat-step-icon-selected, 
.mat-step-header .mat-step-icon-state-done, 
.mat-step-header .mat-step-icon-state-edit {
    color: #fff !important;
}

.mat-step-text-label {
    font-weight: 700;
}

.mat-checkbox-layout .mat-checkbox-label {
    line-height: 17px;
    white-space: initial;
}

.mat-form-field-prefix .mat-icon-button, 
.mat-form-field-suffix .mat-icon-button {
    width: 30px !important;
    height: 30px !important;
}

.mat-form-field {
    font-size: 13px;
}

header {

    .mat-form-field {
        font-size: 15px !important;
    }
    
    .mat-form-field-appearance-fill {
        
        .mat-form-field-infix {
            // padding: 2px 0px 10px 0px !important;
            padding: 0px 0px 18px 10px !important;
        }    

        .mat-form-field-flex {
            // padding: 0px 5px !important;
        }
    }

}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgb(244, 244, 244) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 20px !important;
}

.mat-input-element:disabled, 
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgb(0 0 0) !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, 
.mat-vertical-stepper-header {
    margin-bottom: 15px;
}

.mat-input-element {
    font-weight: 700 !important;
    font-size: 13px !important;
}

.mat-form-field-appearance-legacy .mat-hint {
    color: rgb(0 0 0 / 79%) !important;
}

.snackBar-cust{
    background-color: #1caf9a;
    // background-color: #33a537;
}

.snackBar-cust-update{
    background-color: white;
    color: #000 !important;
    text-transform: capitalize;
    height: 45px;
    display: flex;
    align-items: center;
}

.snackBar-cust-update .mat-simple-snackbar-action {
    color: #2cc4f2 !important;
    margin: 0px;
}

.snackBar-err-cust{
    background-color: #e04b4a;
    color:white;
}

.snackBar-cust,
.snackBar-cust-update,
.snackBar-err-cust{
    font-family: inherit;
    font-size: 11px;
    border-radius: 0px !important;
}

.snackBar-cust .mat-button-wrapper,
.snackBar-err-cust .mat-button-wrapper{
    color: #fff;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    color: white !important;
    letter-spacing: 1px !important;
}

.mat-simple-snackbar {
    font-size: 12px !important;
    width: 100%;
}

.snackBar-cust-update .mat-simple-snackbar{
    line-height: 34px;
}

.mat-snack-bar-container {
  border-radius: 0px !important;
  padding: 5px !important;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.5);
}

.mat-snack-bar-container-processing {
    background: #343a40 !important;
    min-height: 43px;
    display: flex !important;
    align-items: center;    
}

.mat-cell, 
.mat-footer-cell {
    font-size: 12px !important;
    border-bottom-color: rgb(223 223 223) !important;
    color: rgb(0, 0, 0) !important;
}

.mat-hint {
    color: rgba(0, 0, 0, 0.77) !important;
}

.mat-form-field-subscript-wrapper {
    font-size: 10px !important;
    font-weight: 500 !important;
}

th.mat-header-cell, 
td.mat-cell, 
td.mat-footer-cell {
    padding: 2px 5px !important;
}

th.mat-header-cell {
    font-weight: 600;
    font-size: 12px;
}

.mat-drawer-container {
    width: 100vw;
    height: 100vh;
}

.mat-drawer-container {
    background-color: #f6f6f6 !important;
}

.mat-drawer-side {
    border: 0px;
}

.mat-tab-group {

    .mat-tab-header {
        background-color: #fff;
    
        .mat-tab-labels {
    
            .mat-tab-label {
                font-size: 12px;
                opacity: .8 !important;
        
                .mat-tab-label-content {
                    gap: 5px;
                }
            }
        
            .mat-tab-label-active {
                opacity: 1 !important;
                font-weight: 700;
            }
        }   
    
    }

    .mat-tab-body-wrapper {

        .mat-tab-body {
            padding: 10px;
        }
    }
}


.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
    color: rgb(0 0 0 / 70%) !important;
}
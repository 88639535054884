/* You can add global styles to this file, and also import other style files */
@import "./theme.scss";
@import "./fonts.scss";
@import "./assets/css/animate.css";
@import "./assets/css/delays.min.css";
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

html, 
body { 
    height: 100%;
    margin: 0;
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;    
    overflow: hidden !important;
}

* {
    font-family: "Montserrat", sans-serif;
}

button,
.mat-button,
p {
    font-family: "Montserrat", sans-serif;
}

p {
    font-size: 13px;
    line-height: 23px;
    text-align: justify;
    color: #565656;
}

.optional-field,
.required-field {
    font-size: 10px;
    font-style: italic;
    margin-left: 4px;
    opacity: 0.7;
    font-weight: 600;
    top: -1px;
    position: relative;
    right: 0;
}

h3 {
    position: relative;

    .optional-field,
    .required-field {
        position: absolute;
        font-size: 10px !important;
        right: -20px;
        top: 3px;
        font-weight: 600 !important;       
    }
}

.missing,
.required-field {
    color: #f44336;
}

/* 
    
    MAT DIALOGS 
    
*/
.dialogs {
    overflow-y: auto;
    overflow-x: auto;
}

.complaint-letter-dialog,
.organisation-form-3-dialog,
.organisation-upload-dialog {
    width: 700px;
}

.organisation-certificate-dialog {
    width: 1122px;
    height: 720px;
    overflow-x: hidden;
}

.organisation-registration-preview-form-3-dialog { 
    width: 850px;
    overflow-x: hidden;
}

.organisation-country-dialog,
.organisation-third-party-dialog,
.organisation-security-measure-dialog,
.organisation-data-protection-officer-dialog,
.organisation-main-activity-dialog {
    width: 800px;
}

.successful-message-dialog {
    width: 550px;
}

/* BOTTOMSHEETS */
.bottomsheets {
    border-radius: 0px !important;
    padding: 0px !important;
    max-width: 100vw !important;

    .mat-progress-bar-container {
        top: 56px;
    }    
}

.view-pdf-document-bottomsheet {
    height: 100vh;
    // width: 70vw;
    width: 64vw;
    max-height: 89vh !important;
    border-radius: 0;
    padding: 0 !important;
}


/* MAT FORM FIELDS */
.mat-form-field {

    .clear-mat-icon {
        position: absolute;
        top: -13px;
        right: 6px;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px !important;
        cursor: pointer;
        background-color: #e2e2e2;
    }
    
    .clear-mat-icon-main-page {
        position: absolute;
        top: -13px;
        right: -2px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px !important;
        cursor: pointer;
        background-color: #e0dddd;
    }    
}

.search-input {

    .mat-form-field-appearance-fill .mat-form-field-infix {
        padding: 4px 0px 10px 0px !important;
    }
}

/* 

    PROGRESS BAR 

*/
.mat-progress-bar-container {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 4px;
    overflow: hidden;
}

app-pdf-reader,
app-inline-mat-spinner {

    .mat-progress-spinner {
       width: 30px !important;
       height: 30px !important;
       
       svg {
           width: 30px !important;
           height: 30px !important;
       }
   }
}

.inline-processing-btn {
    width: fit-content !important;
    width: -moz-fit-content !important;

    div.btn-label,
    span.mat-button-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        color: #3333339e;
    }
}

/*

    NEWS COMPONENT

*/
app-news {

    main {

        p {
            font-size: 12px;
            line-height: 16px;
            text-align: justify;
            margin: 0px 0px 0px;
            color: #373737;
        }   
    }
}

app-individuals,
app-organisations,
app-about-us,
app-read-all-news {

    article.main-wrapper {

        p {
            font-size: 14px;
            line-height: 28px;
            text-align: justify;
            margin: 0px;
            color: #373737;
            font-weight: 400;                        
        }         
    }

    article.more-news {

        p {
            font-size: 12px;
            line-height: 20px;
            text-align: justify;
            margin: 0px 0px 0px;
            color: #373737;
        }          
    }

    ul,
    ol {
        // padding-inline-start: 20px;  
        line-height: 28px;
        text-align: justify;
        margin: 5px 0px 0px;
        color: #373737;
        font-weight: 500;    
        font-weight: 400;    
        
        li {
            margin-bottom: 25px;  
            font-size: 14px;
            
            &:first-child {
                margin-top: 20px;
            }
            
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    ul {
        list-style: square;
    }
}

.no-items-found {
    text-align: center;
}


app-search {
    .mat-form-field-appearance-fill .mat-form-field-flex {
        border-radius: 4px 4px 0 0;
        padding: 0px;
        background-color: #fff !important;
    }   
}

/*

    INFORMATION CENTER

*/
app-individuals,
app-organisations {

    section.dynamic {

        p {
            font-size: 14px;
            line-height: 32px;
            text-align: justify;
            margin: 5px 0px 15px;
            color: #373737;
            font-weight: 400;
            margin-top: 1em;
            // margin-bottom: -0.46em;        
        }
    }
}

app-faqs {
    .mat-paginator {
    
        .mat-paginator-outer-container {
            
            .mat-paginator-container {
                justify-content: center;
            }
        }
    }    
}

app-resources {

    .no-items-found {
        text-align: center;
        border-bottom: 1px solid #e0e0e0;
        font-size: 12px;
        font-weight: 300;

        b {
            font-weight: 600;
        }
    }
}

app-information-center {

    header {

        mat-form-field {
            width: 440px;

            .search-mat-icon {
                width: 38px;
                font-size: 23px !important;
                position: relative;
                bottom: -4px;              
            }       

            .mat-form-field-underline {
                width: 91%;
                margin: auto 0px 0px 20px;
            }              
        }    
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
        border-radius: 34px !important;
        padding: 8px .75em 0 .75em !important;
    }         
}

/*

    FORMS

*/
app-register-complaint,
app-register-organisation {

    .mat-step-header {

        .mat-step-icon {
            border-radius: 50%;
            height: 38px;
            width: 38px;
            margin-top: -7px;
            background-color: rgba(182, 182, 182, 0.54);
            color: #3a3a3a;            
        }

        .mat-step-icon-selected {
            background-color: #aec836;
            color: #000 !important;
        }
    
        .mat-step-icon-state-done,
        .mat-step-icon-state-edit {
            background-color: #333;
        }    
            
        .mat-step-text-label {
    
            h3 {
                margin: 5px 0px 5px;
                font-weight: 600;
                font-size: 12px;
            }
    
            p {
                margin: 0px 0px 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.76);
            }
        }    
    }    
}


/* 

    MAT MENU
    
*/
.more-options-mat-menu {
    border-radius: 0px !important;
}

.more-options-mat-menu {
    border-radius: 0px !important;
    min-height: 58px !important;

    hr {
        border: 0px;
        border-bottom: 1px solid #e0e0e0;
        margin: 0px;
    }

    button {
        line-height: 40px;
        height: 40px;      
        padding-right: 20px;

        .btn-label {
            display: grid;
            grid-template-columns: 30px auto;
            grid-gap: 10px;
            align-items: center;

            img {
                width: 20px;
            }            
        }
    }

    button.inline-processing {
        display: grid;
        grid-template-columns: auto auto;

        mat-icon {
            display: none;
            visibility: hidden;
        }
    }

    .activate-user {

        mat-icon {
            color: #1caf9a;
        }
    }

    .color-green {
        color: #1caf9a !important;
    }
}

.pdpo-header-mat-menu {
    border-radius: 0px !important;
    margin-top: -1px;
    background: #323636;
    min-width: 270px !important;
    min-height: 53px !important;

    .mat-menu-content:not(:empty) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        width: 100%;
    }
    
    .mat-menu-content {

        button {
            color: #fff;
            font-size: 13px !important;
            font-weight: 400;
            line-height: 53px;
            height: 56px;

            &:focus,
            &:hover {
                color: #9fc341;             
            }
        }

        .activate-route {
            background-color: #9fc341;
            color: #fff !important;
        }

        .mat-menu-item-submenu-trigger::after {
            transform: translateY(-50%);
            color: #fff !important;
        }          
        
    }
}

/*

    MEDIA QUERIES

*/
@media (max-width: 1140px) {
    app-news {

        main {

            section.main-story {

                h3 {
                    font-size: 16px !important;
                    line-height: 18px !important;
                    margin-bottom: 12px !important;
                }                
            }

            article {

                div {

                    h3 {
                        font-size: 11px !important;
                        line-height: 16px !important;
                        text-align: left !important;               
                    }
            
                    p {
                        font-size: 11px;
                        line-height: 15px;
                        text-align: left;
                    }   
                }
            }
        }
    }
}

@media (max-width: 640px) {
    app-individuals,
    app-organisations,
    app-about-us,
    app-read-all-news {
    
        article.main-wrapper {
    
            p {
                font-size: 13px;                       
            }         
        }
    
        ul,
        ol {
            // padding-inline-start: 20px;  
            line-height: 28px;
            text-align: justify;
            margin: 5px 0px 0px;
            color: #373737;
            font-weight: 500;    
            font-weight: 400;    
            
            li {
                margin-bottom: 20px;  
                font-size: 13px;
                
                &:first-child {
                    margin-top: 15px;
                }
                
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    
        ul {
            list-style: square;
        }
    }
    
}

// @media(max-width: 450px) {
//     .pdpo-projects-header-menu {
//         margin-top: 9px;
//     }
    
//     .pdpo-projects-header-menu {
//         height: fit-content;    
//         height: -moz-fit-content;
//     }  
// }


/*

    MIN-WIDTH

*/
@media(min-width: 1655px){
    .skitter{
        height: 460px !important;
    }

    .header {
        height: 330px !important;
    }   
}